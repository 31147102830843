.container{
    margin-top: 30px;
}
.icon{
    color:#003865;
}
.col-one{
    width: fit-content;
}
.col-two{
    margin-top: 10px;
    width: fit-content;
}
.col-three{
    width: 300px;
}
.name{
    font-weight: bold;
    font-size: 33px;
    padding: 10px;
    color: #737373;
}
.text{
    font-weight: bold;
    font-size: 23px;
    padding: 10px;
    color: #737373;
}
.button-normal{
    background-color: #003865;
    width: 100%;
    height: 80px;
    color: #ebebeb;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    border-radius: 0px;
}
.button-normal:hover{
    background-color: #003865;
}
.button-circle{
    width: 200px;
    height: 200px;
    padding: 10px 16px;
    border-radius: 150px;
    font-size: 24px;
    line-height: 1.33;
    color: #ebebeb;
    font-weight: bold;
    font-size: 18px;
    background-color: #003865;
    align-self: center;
    margin-top: 10px;
}
.button-circle:hover{
    background-color: #003865;
}

.button-square{
    width: 200px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    color: #ebebeb;
    font-weight: bold;
    font-size: 18px;
    background-color: #003865;
    align-self: center;
    margin-top: 10px;
}

.button-square:hover{
    background-color: #003865;
}


.home-list{
    margin-top: 20px;
    border-style: solid;
    background-color: '#fff';
    border-radius: 4;
    border-color: #737373;
    align-items:center;
    justify-content:center;
    height: 20rem;
    overflow: auto;
}
.first-row{
    width: 100%;
}