.check_color input:checked {
    background-color: #efc2c7;
    border-color: #b82f3f;
}

.check_color input:focus {
    box-shadow: none;
    background-color: none;
}

.check_color input:hover {
    border-color: #b82f3f;
}

.check_color input::after {
    background-color: none;
}

