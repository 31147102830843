.link_text {
    font-size: 1em;
    color: #b82f3f;
}

.link_text:hover {
    color: #D65E6C;
}

.link_text:focus {
    color: #D65E6C;
}
