#table_style {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 90%;
}

#table_style td,
#table_style th {
    border: 1px solid #ddd;
    padding: 8px;
}

#table_style tr:nth-child(even) {
    background-color: #f2f2f2;
}

#table_style tr:hover {
    background-color: #ddd;
}

#table_style th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #b82f3f;
    color: white;
}